<template>
  <div class="mt-3">
    <p v-show="errorUsuarios">Error obteniendo los usuarios</p>

    <h3 v-show="loadingUsuarios" class="text-center"><i class="fas fa-circle-notch fa-spin"></i></h3>

    <div v-if="!errorUsuarios && !loadingUsuarios" class="text-center">
      <p>Últimos <strong>{{ usuarios.length }}</strong> usuario/s</p>

      <div v-for="usuario in usuarios" :key="usuario._id">

        <router-link :to="{ name: 'Usuario', params: { embyId: usuario.embyId } }" class="not-active">
          <div class="row m-3 shadow align-items-center border bg-light text-center">
            <div class="col-sm">
              <img src="../../assets/images/user.png" class="card-img-top img-fluid rounded-circle" style="max-width: 50px">
              <h5>{{ usuario.usuario }}</h5>
            </div>

            <div class="col-sm">
              <h5>{{ usuario.nombreCompleto }}</h5>
            </div>

            <div class="col-sm">
              <h5><i class="fas fa-calendar-day text-primary"></i> {{ new Date(usuario.fechaRegistro).toLocaleDateString() }}</h5>
            </div>

            <div class="col-sm">
              <h5>{{ usuario.correo }}</h5>
            </div>

            <div class="col-sm text-center">
              <h5>{{ usuario.diasRestantes }}</h5>
            </div>

            <div class="col-sm text-center">

                <h5 v-if="usuario.activado"><span class="badge badge-success">Activado</span></h5>

                <h5 v-else><span class="badge badge-danger">Bloqueado</span></h5>

            </div>
          </div>
        </router-link>

      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import config from '../../config'

  export default {
    data() {
      return {
        errorUsuarios: false,
        usuarios: [],
        loadingUsuarios: false
      }
    },
    methods: {
      async getUsuarios() {
        try {
          this.loadingUsuarios = true
          const res = await axios.get(config.apiUrl + '/usuarios')
          this.loadingUsuarios = false
          this.usuarios = res.data.slice(0, 10)
        } catch (e) {
          this.errorUsuarios = true
          this.loadingUsuarios = false
        }
      },
    },
    mounted() {
      this.getUsuarios()
    }
  }
</script>
